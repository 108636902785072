import { getHttpClient } from "@root/httpClient";
import { getHttpClientV2 } from "@root/httpClientV2";
import {
  setGetDashboardDataDone,
  setGetWithdrawalConditionsDone,
  setHasTimeRedux,
} from "@root/store/counter/appStatusSlice";
import { setTabNumber } from "@root/store/counter/changeTabSlice";
import { setExchangeCompleted } from "@root/store/counter/checkExchangeCompletedCashDSlice";
import { setDashboardData } from "@root/store/counter/dashboardDataSlice";
import {
  clearDeductionCashD,
  setDeductionsPage,
} from "@root/store/counter/deductionsCashDSlice";
import { setIsAuthenticated } from "@root/store/counter/loginSlice";
import {
  clearTransactionCashD,
  setTransactionsPage,
} from "@root/store/counter/transactionsCashDSlice";
import { setUser } from "@root/store/counter/userInfoSlice";
import { getWhiteLabellingData } from "@root/store/counter/whiteLabellingSlice";
import { MD5 } from "crypto-js";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
//GOOGLE ANALYTICS import
import ReactGA from "react-ga4";

export interface UserBaseInfor {
  staff_id: string;
  company_id: string;
  countryData: any;
}

let userBaseInfor: UserBaseInfor = {
  staff_id: "",
  company_id: "",
  countryData: {
    countryCurrencyCode: "",
    countryName:"",
    currencySymbol:"",
  },

};

export default function useLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getCodeLogin = async (email: string) => {
    const httpClient = getHttpClient();
    try {
      const { data } = await httpClient.post<any>("/api/user/getotp", {
        email,
      });
      // return data.result;
      return data;
    } catch (error: any) {
      if (error.response) {
        return {
          // success: false,
          // message: error?.response?.data?.message,
          code: error?.response?.data?.result?.code,
          message: error?.response?.data?.result?.message,
        };
      } else {
        return {
          success: false,
          message: "Invalid code",
        };
      }
    }
  };

  const login = async (
    email: string,
    isLoginOTP: boolean,
    otpCode: string,
    password: string,
    loginType: string,
    finalDeviceId: number
  ) => {
    const httpClient = getHttpClient();
    try {
      const { data } = await httpClient.post<{
        result: {
          success: boolean;
          response: any;
        };
      }>("/api/user/loginbyotporpassword", {
        email: email,
        password: isLoginOTP ? otpCode : password,
        login_type: loginType,
        time_offset: "25200000",
        device_id: finalDeviceId,
      });
      return data;
    } catch (error: any) {
      if (error?.response?.data?.message === "Not found 'Code' in system") {
        return {
          success: false,
          message: error?.response?.data?.message,
        };
      } else if (error?.response?.data?.message === "Your Code has expired") {
        return {
          success: false,
          message: error?.response?.data?.message,
        };
      } else {
        return {
          success: false,
          message: error?.response?.data?.message
            ? error?.response?.data?.message
            : "Login failed!",
        };
      }
    }
  };

  const verifyOTP = async (email: string, OTP: string) => {
    const httpClient = getHttpClient();
    try {
      const { data } = await httpClient.post<{
        result: {
          success: boolean;
          response: any;
        };
      }>("/api/user/verify-otp", {
        email: email,
        OTP: OTP,
      });
      let _data: any = data;
      let userToken = _data?.result?.data?.userData?.token;
      localStorage.setItem("token", userToken);
      localStorage.setItem("userId", _data?.result?.data?.userData?._id);
      userBaseInfor.staff_id = _data?.result?.data?.staffData?._id;
      userBaseInfor.company_id = _data?.result?.data?.companyData?._id;
      userBaseInfor.countryData = _data?.result?.data?.countryData;
      localStorage.setItem("countryData", JSON.stringify(_data?.result?.data?.countryData));
      localStorage.setItem("userInfor", JSON.stringify(userBaseInfor));
      return data;
    } catch (error: any) {
      if (error.response) {
        return {
          // success: false,
          code: error?.response?.data?.code,
          message: error?.response?.data?.message,
        };
      }
    }
  };

  const verifyOTPUnregistered = async (email: string, OTP: string) => {
    const httpClient = getHttpClient();
    try {
      const { data } = await httpClient.post<{
        result: {
          success: boolean;
          response: any;
        };
      }>("/api/user/verify-otp-unregistered", {
        email: email,
        OTP: OTP,
      });
      let _data: any = data;
      let userToken = _data?.result?.data?.token;
      sessionStorage.setItem("temptoken", userToken);
      return data;
    } catch (error: any) {
      if (error.response) {
        return {
          // success: false,
          code: error?.response?.data?.code,
          message: error?.response?.data?.message,
        };
      }
    }
  };

  const getUserProfile = async (userId: string) => {
    const httpClient = getHttpClientV2();
    try {
      const { data } = await httpClient.get<{
        result: {
          type: string;
          statusCode: number | string;
          code: number;
          data: any;
        };
      }>(`/api/user/get-user-profile`, {
        params: {
          userId,
        },
      });
      let _data: any = data;
      userBaseInfor.staff_id = _data?.result?.data?.staffs?._id;
      userBaseInfor.company_id = _data?.result?.data?.companies?._id;
      userBaseInfor.countryData = _data?.result?.data?.company_country;
      console.log("USERT BASE INFOR",userBaseInfor);
      localStorage.setItem("userInfor", JSON.stringify(userBaseInfor));
      dispatch(
        getWhiteLabellingData({
          logoUrl: _data?.result?.data?.companies?.brand?.logoUrl,
          primaryColor: _data?.result?.data?.companies?.brand?.primaryColor,
          secondaryColor: _data?.result?.data?.companies?.brand?.secondaryColor,
          custom: _data?.result?.data?.companies?.brand?.custom,
        })
      );
      return data;
    } catch (error: any) {
      console.log(error.type);
      return {
        type: error.result,
        message: "Get user profile failed!",
      };
    }
  };

  const getToken = async (device_id: string, refresh_token: string) => {
    const httpClient = getHttpClient();
    try {
      const { data } = await httpClient.post<{
        result: {
          success: boolean;
          response: any;
        };
      }>("/api/user/get-token", {
        device_id,
        refresh_token,
      });
      return data;
    } catch (error: any) {
      if (error?.response?.data?.message === "Not found 'Code' in system") {
        return {
          success: false,
          message: error?.response?.data?.message,
        };
      } else if (error?.response?.data?.message === "Your Code has expired") {
        return {
          success: false,
          message: error?.response?.data?.message,
        };
      } else {
        return {
          success: false,
          message: "Login failed!",
        };
      }
    }
  };

  const getForgotCodePassword = async (email: string) => {
    const httpClient = getHttpClient();
    try {
      const { data } = await httpClient.get<{
        result: {
          success: boolean;
          response: any;
        };
      }>(`/api/user/get-code-forgot-password`, {
        params: {
          email,
        },
      });
      return data;
    } catch (error: any) {
      if (error?.response?.data?.message === "Not found 'Code' in system") {
        return {
          success: false,
          message: error?.response?.data?.message,
        };
      } else if (error?.response?.data?.message === "Your Code has expired") {
        return {
          success: false,
          message: error?.response?.data?.message,
        };
      } else {
        return {
          success: false,
          message: "Login failed!",
        };
      }
    }
  };

  const enterCodeForgotPassword = async (email: string, code: string) => {
    const httpClient = getHttpClient();
    try {
      const { data } = await httpClient.post<{
        result: {
          success: boolean;
          response: any;
        };
      }>("/api/user/enter-code-forgot-password", {
        email,
        code,
      });
      return data;
    } catch (error: any) {
      if (error?.response?.data?.message === "Your Code was expired") {
        return {
          success: false,
          message: error?.response?.data?.message,
        };
      } else if (
        error?.response?.data?.message === 'Not found "Code" in system'
      ) {
        return {
          success: false,
          message: "Code is invalid!",
        };
      } else {
        return {
          success: false,
          message: "Verify forgot password code failed!",
        };
      }
    }
  };

  const createNewPassword = async (
    new_password: string,
    email: string,
    code: string
  ) => {
    const httpClient = getHttpClient();
    try {
      const { data } = await httpClient.post<{
        result: {
          success: boolean;
          response: any;
        };
      }>("/api/user/create-new-password", {
        new_password: MD5(new_password).toString(),
        email,
        code,
      });
      return data;
    } catch (error: any) {
      if (error?.response?.data?.message === "Your Code was expired") {
        return {
          success: false,
          message: error?.response?.data?.message,
        };
      } else if (
        error?.response?.data?.message === 'Not found "Code" in system'
      ) {
        return {
          success: false,
          message: "Code is invalid!",
        };
      } else {
        return {
          success: false,
          message: "Verify forgot password code failed!",
        };
      }
    }
  };

  const registerEmployee = async (activationCode: string) => {
    const httpClient = getHttpClient();
    try {
      const { data } = await httpClient.post<{
        result: {
          success: boolean;
          response: any;
        };
      }>(`/api/user/register-employee?activationCode=${activationCode}`);
      return data;
    } catch (error: any) {
      if (error?.response?.data?.message === "Your Code was expired") {
        return {
          success: false,
          message: error?.response?.data?.message,
        };
      } else if (
        error?.response?.data?.message === 'Not found "Code" in system'
      ) {
        return {
          success: false,
          message: "Code is invalid!",
        };
      } else {
        return {
          success: false,
          message: "Verify forgot password code failed!",
        };
      }
    }
  };

  const inviteUser = async (activationCode: string) => {
    const httpClient = getHttpClient();
    try {
      const { data } = await httpClient.get<{
        result: {
          success: boolean;
          response: any;
        };
      }>("/api/user/user-invitation", {
        params: { activationCode },
      });
      return data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data?.message === "Your Code was expired") {
        return {
          success: false,
          message: error?.response?.data?.message,
        };
      } else if (
        error?.response?.data?.message === 'Not found "Code" in system'
      ) {
        return {
          success: false,
          message: "Code is invalid!",
        };
      } else {
        return {
          success: false,
          message: "Verify forgot password code failed!",
        };
      }
    }
  };

  const acceptTNC = async (token: string) => {
    const httpClient = getHttpClientV2();
    try {
      const { data } = await httpClient.post<{
        result: {
          success: boolean;
          response: any;
        };
      }>("/api/user/accept-tnc-unregistered", {
        token
      });
      return data;
    } catch (error: any) {
      console.log(error);
      if (error?.response?.data?.message) {
        return {
          success: false,
          message: error?.response?.data?.message,
        };
      } else {
        return {
          success: false,
          message: "Code is invalid!",
        };
      }
    }
  };

  const handleLogout = () => {
    ReactGA.event({
      category: "User Interaction",
      action: "Clicked “Yes” on the logout pop up",
      label: "Button Clicked",
    });
    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("userInfor");
    dispatch(setTabNumber(0));
    dispatch(setIsAuthenticated(false));
    dispatch(clearTransactionCashD([]));
    dispatch(clearDeductionCashD([]));
    dispatch(setExchangeCompleted(false));
    dispatch(setTransactionsPage(0));
    dispatch(setDeductionsPage(0));
    dispatch(setGetDashboardDataDone(false));
    dispatch(setGetWithdrawalConditionsDone(false));
    dispatch(setHasTimeRedux(false));
    dispatch(setUser(null));
    dispatch(setDashboardData(null));
    navigate("/login");
  };

  return {
    getCodeLogin,
    login,
    getToken,
    getUserProfile,
    getForgotCodePassword,
    enterCodeForgotPassword,
    createNewPassword,
    registerEmployee,
    inviteUser,
    handleLogout,
    verifyOTP,
    verifyOTPUnregistered,
    acceptTNC
  };
}
