// @ts-nocheck
import { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { ScreenPaths } from "./configs/paths";
import { Provider } from "react-redux";

import CssBaseline from "@mui/material/CssBaseline";
import PrivateRoute from "./PrivateRoute";
import store from "@root/store/store";
import theme from "./theme";

//CSS imports
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";

//GOOGLE ANALYTICS import
import ReactGA from "react-ga4";
import Loader from "./configs/Loader";
import { PublicRoute } from "./PublicRoute/PublicRoute";
import TNCPrivateRoute from "./TNCPrivateRoutes";

const LoginV2 = lazy(() => import("@screens/LoginV2/LoginV2"));
const OTP = lazy(() => import("@screens/OTP/OTPScreen"));
// const LoginWithPassword = lazy(
//   () => import("@screens/LoginV2/LoginWithPassword")
// );

const WithdrawScreen = lazy(() => import("@screens/Withdraw/WithdrawScreen"));
const Transactions = lazy(() => import("@screens/Transactions/Transactions"));
const LandingScreen = lazy(() => import("@screens/Landing/LandingScreen"));
const LandingScreenUnregisteredUser = lazy(() => import("@screens/Landing/LandingScreenUnregisteredUser"));
const BalanceScreen = lazy(() => import("@screens/Balance/BalanceScreen"));
const ExpiryPage = lazy(() => import("@screens/ExpiryPage/ExpiryPage"));
const ErrorPage = lazy(() => import("@screens/ErrorPage/ErrorPage"));
const ProfileScreen = lazy(() => import("@screens/ProfileScreen"));
const Summary = lazy(() => import("@screens/Summary/Summary"));
const EOI = lazy(() => import("@screens/EOI/Eoi"));
const FAQS = lazy(() => import("@screens/FAQ/FAQS"));

function App() {
  const token = localStorage.getItem("token");
  const redirectPath = token ? ScreenPaths.BALANCE : ScreenPaths.LOGIN_PATH;
  ReactGA.initialize(
    process.env.REACT_APP_GA_MEASUREMENT_ID || "G-3NY89W7XXB",
    {
      // debug: true,
    }
  );
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Provider store={store}>
        <BrowserRouter>
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path="/" element={<Navigate to={redirectPath} />} />

              {/* Public Routes */}
              <Route path="/" element={<PublicRoute />}>
                <Route path={ScreenPaths.LOGIN_PATH} element={<LoginV2 />} />
                {/* <Route
                  path={ScreenPaths.LOGIN_WITH_PASSWORD_PATH}
                  element={<LoginWithPassword />}
                /> */}
                <Route
                  path={ScreenPaths.TERMS_CONDITIONS_PATH}
                  element={<LandingScreen />}
                />
                <Route
                  path={ScreenPaths.KILIMANJARO_EOI}
                  element={<EOI />}
                />
                <Route
                  path={ScreenPaths.KILIMANJARO_FAQ}
                  element={<FAQS />}
                />

                <Route
                  path={ScreenPaths.ACTIVATION_CODE_EXPIRY_PATH}
                  element={<ExpiryPage />}
                />
                <Route path={ScreenPaths.OTP} element={<OTP />} />
              </Route>
              {/* TNC Private Routes */}
              <Route path="/" element={<TNCPrivateRoute />}>
                <Route
                  path={ScreenPaths.TERMS_AND_CONDITIONS_UNREGISTERED_PATH}
                  element={<LandingScreenUnregisteredUser />}
                />
              </Route>

              {/* Private Routes */}
              <Route path="/" element={<PrivateRoute />}>
                <Route path={ScreenPaths.BALANCE} element={<BalanceScreen />} />
                <Route
                  path={ScreenPaths.WITHDRAWAL_PATH}
                  element={<WithdrawScreen />}
                />
                <Route
                  path={ScreenPaths.TRANSACTIONS_PATH}
                  element={<Transactions />}
                />
                <Route
                  path={ScreenPaths.DEDUCTION_PATH}
                  element={<Summary />}
                />
                <Route
                  path={ScreenPaths.PROFILE_PATH}
                  element={<ProfileScreen />}
                />
              </Route>

              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
